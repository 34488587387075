import React from 'react'
import millify from 'millify'
import GoalImg from '../../../assets/img/goal.png'


function ModelItem({model, onSelectModel}) {
  return (
    <li className="d-flex no-block card-body justify-content-between" onClick={() => onSelectModel(model)}>
    <div className="w-3/4 d-flex gap-3">
      <img style={{ "width": "50px", height: "50px" }} src={ model.cover_image_url || GoalImg} alt="" />
      <div>
        <p>
          <a target='_blank' href={model.url} className="m-b-0 font-medium p-0" data-abc="true">
            {model.model}
          </a>
        </p>
        <span className="text-muted">
          {model.description}
        </span>
      </div>
    </div>
    <div className="ml-auto">
      <div className="tetx-right">
        <span className="text-muted font-16 d-block">
          {millify(model.run_count)}
        </span>
        <span className="text-muted font-16">runs</span>
      </div>
    </div>
  </li>  )
}

export default ModelItem