import React, { useEffect, useState } from 'react'
import { FaStop } from 'react-icons/fa6'
import { WP_URL } from '../lib/config'
import { useSignIn } from 'react-auth-kit'
import { getAuthUserWithWp, registerWithWp } from '../requests/fetch'
import { MdOutlineCircle } from "react-icons/md";
import { Link, useSearchParams } from 'react-router-dom'




function RegisterPage() {
  const signIn = useSignIn()
  let [searchParams, setSearchParams] = useSearchParams();

  const [form, setForm] = useState({
    email: "",
    password: "",
    coupon: ""
  })

  const [registerError, setRegisterError] = useState<string | null>(null)
  const [isLoading, setLoading] = useState(false)
  const coupon = searchParams.get('coupon')

  useEffect(() => {
    if (coupon && coupon.length > 10 ) {
      setForm(form => ({...form, coupon}))
    }
  }, [coupon])

  const handleSubmit = async (e) => {
    setLoading(true)
    setRegisterError(null)
    e.preventDefault()
    const tokenResponse = await registerWithWp(form).catch(err => {
      if (err.response?.data?.code == "email_exists") {
        setRegisterError('This email is already registered. Please try another email.')
      } else {
        setRegisterError('Something went wrong. Please try again.')
      }
      setLoading(false)
    })

    if (!tokenResponse) {
      return
    }

    const token = tokenResponse?.data.jwt
    const user = tokenResponse?.data.user

    let response;

    if (!user && response && token) {
      // response = await getAuthUserWithWp(token).catch(err => {
      //   setLoading(false)
      //   setRegisterError(err.response?.data?.data?.message || err.message)
      // })
      if (response) {
        const res = response.data
        signIn({
          token,
          expiresIn: 7100,
          tokenType: "Bearer",
          authState: res.data.user,
        })
      }
    } else {
      signIn({
        token,
        expiresIn: 7100,
        tokenType: "Bearer",
        authState: user,
      })
    }
    setLoading(false)
    window.location.replace("/")
  }


  return (
    <>
      <div id="appCapsule">
        <div className="row">
          <div className="col-sm-12 col-md-3 mx-auto">
            <div className="section mt-2 text-center">
              <h1>Register</h1>
              <h4>Fill the form to register</h4>
            </div>
            <div className="section mb-5 p-2">
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body pb-1">
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email1"
                          placeholder="Your email"
                          value={form.email}
                          onChange={e => setForm({ ...form, email: e.target.value })}
                        />
                        <i className="clear-input">
                          <FaStop />
                        </i>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="password1">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password1"
                          autoComplete="off"
                          placeholder="Your password"
                          value={form.password}
                          onChange={e => setForm({ ...form, password: e.target.value })}
                        />
                        <i className="clear-input">
                          <FaStop />
                        </i>
                      </div>
                    </div>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">
                          Coupon Code (optional)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="code1"
                          placeholder="XXXX-XXXX-XXXX-XXXX"
                          value={form.coupon}
                          onChange={e => setForm({ ...form, coupon: e.target.value })}
                        />
                        <i className="clear-input">
                          <FaStop />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  registerError && (
                    <div className="d-flex justify-content-center mt-3">
                      <div className="alert alert-danger mb-1" role="alert">
                        {registerError || 'Login attempt failed. Please try again.'}
                      </div>
                    </div>
                  )
                }
                <div className="form-button mt-3  transparent">
                  <button disabled={isLoading} type="submit" className="btn btn-primary btn-block btn-lg">
                    Register&nbsp;
                    {
                      isLoading && (

                        <span className='animate__animated animate__heartBeat animate__infinite'>
                          <MdOutlineCircle />
                        </span>
                      )
                    }
                  </button>
                </div>
              </form>
              <div className="form-links mt-2">
                <div>
                  <Link to={`/login`}>Login Now</Link>
                </div>
                <div>
                  <a href={`${WP_URL}/forgot-password `} className="text-muted">
                    Forgot Password?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default RegisterPage