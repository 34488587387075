import { TCreditPurchaseLog } from "./CrediPurchasetHistory";
import { TExecutionHistoryLog } from "./ExecutionHistory";

export type TUserVoice = {
  voice_id?: string | number | undefined;
  voice_name: string;
  voice_label?: string;
  id?: string|number;
  user_id?: string| null|number;
}



export type TAppUser = {
  ID: number;
  user_login: string;
  user_pass: string;
  user_nicename: string;
  user_email: string;
  user_url: string;
  user_registered: string; // Typically a date string
  user_activation_key: string;
  user_status: number;
  display_name: string;
  roles: string[];
};

export type TCreateAppUser = {
  email: string;
  voiceIds: TUserVoice[];
  creditPurchaseHistory: TCreditPurchaseLog[];
  executionHistory: TExecutionHistoryLog[];
  downloadIds: string[];
  id: any;
  createdAt: any;
  updatedAt: any;
  ID: number;
  user_login: string;
  user_pass: string;
  user_email: string;
};

export type TUpdatedAppUser = {
  id?: string
  email: string;
  voiceIds?: TUserVoice[];
  creditPurchaseHistory?: TCreditPurchaseLog[];
  executionHistory?: TExecutionHistoryLog[];
  downloadIds?: string[];

  createdAt?: number;
  updatedAt?: number;
};

export class AppUser {
  // @ts-ignore
  id: string;
  email: string;
  voiceIds: TUserVoice[];
  creditPurchaseHistory: TCreditPurchaseLog[];
  executionHistory: TExecutionHistoryLog[];
  downloadIds: string[];
  // @ts-ignore
  createdAt: number;
  // @ts-ignore
  updatedAt: number;
  constructor(user: any) {
    this.email = user.email;
    this.voiceIds = user.voiceIds;
    this.creditPurchaseHistory = user.creditPurchaseHistory!;
    this.executionHistory = user.executionHistory!;
    this.downloadIds = user.downloadIds;

    if (user.id) {
      this.id = user.id;
    }
    if (user.createdAt) {
      this.createdAt = user.createdAt;
    }
    if (user.updatedAt) {
      this.updatedAt = user.updatedAt;
    }


  }

  setId(id: string) {
    this.id = id;
  }

  async addVoiceId(voiceId: TUserVoice) {
    this.voiceIds.push(voiceId);
  }

  async addCreditPurchaseHistory(creditPurchaseId: TCreditPurchaseLog) {
    this.creditPurchaseHistory.push(creditPurchaseId);
  }

  async addExecutionHistory(executionId: TExecutionHistoryLog) {
    this.executionHistory.push(executionId);
  }

  async addDownloadId(downloadId: string) {
    this.downloadIds.push(downloadId);
  }

  static async create(user: TAppUser) {
    const appUser = new AppUser(user);
    appUser.createdAt = Date.now();
    return appUser;
  }


}

export class AuthAppUser extends AppUser {

  constructor(user: TCreateAppUser) {

    if (!user.voiceIds) {
      user.voiceIds = [];
    }
    if (!user.creditPurchaseHistory) {
      user.creditPurchaseHistory = [];
    }
    if (!user.executionHistory) {
      user.executionHistory = [];
    }
    if (!user.downloadIds) {
      user.downloadIds = [];
    }

    super(user);
  }

}

type EnhancementType = "gfpgan" | "RestoreFormer";
type PreprocessType = "crop" | "resize" | "full";

export interface StPredictionInput {
  source_image: string;
  driven_audio: string;
  enhancer: EnhancementType;
  preprocess: PreprocessType;
  ref_eyeblink: string;
  ref_pose: string;
  still: boolean;
}

export interface StPredictionArgs {
  source_image: string | null;
  driven_audio: string | null;
  ref_eyeblink: string | null;
  ref_pose: string | null;
}



export type TGetConversationArgs = {
  after?: string;
  agentName?: string;
  sessionId: string;
};

export type TMessage = {
  id: string;
  content: string;
  sender: string;
  destination: string;
  createdAt: string;
  agentSessionId: string;
  props: any
};

export type TAgent = {
  props: any;
  id: string;
  name: string;
  agentType: AGENT_TYPE;
};

export type TAddAgentArgs = {
  name: string;
  taskDescription?: string;
  goal: string;
  backstory: string;
  agentType: AGENT_TYPE;
  documentUrl?: string;
  sessionId?: string;
};

export enum AGENT_TYPE {
  SESSION_AGENT = "session_agent",
  DOCUMENT_AGENT = "document_agent",
  CUSTOM_AGENT = "custom_agent",
  PUBLIC_AGENT = "public_agent",
  WOOCOMMERCE_SHOP_AGENT = "woocommerce_shop_agent",
  WOOCOMMERCE_ADMIN_AGENT = "woocommerce_admin_agent",
  SHARED_AGENT = "shared_agent",
}


export type TSession = {
  rootSession: {id: string, props: any};
  id: string
  sessionName: string
  userId: string
  props: any
  createdAt: string
}
export type TCreateSessionMessageArgs = {
  messageId: string;
  sessionId: string;
  destination?: string;
  content: string;
  agentType?: string;
  messageType?: TMessageType;
  props?: any;
  selectedLLM?: {
    name: string
    provider: string
  };
};

export type TMessageType = typeof TMessageEnum[keyof typeof TMessageEnum];

export const TMessageEnum = Object.freeze({
  // user sends a message
  USER_MESSAGE: "user_message",
  // agent sends a message
  AGENT_MESSAGE: "agent_message",
  // user replies to an agent message
  USER_REPLY: "user_reply",
  // agent replies to a user message
  BASIC_TASK_REPLY: "basic_task_reply",
  TURBO_TASK_REPLY: "turbo_task_reply",
  // agent describes a task from a user message
  BASIC_TASK_SPEC: "basic_task_spec",
  // agent describes a task from a user message
  TURBO_TASK_SPEC: "turbo_task_spec",
  TASK_SPEC: "task_spec"
})


export type TToolData = {
  name: string;
  data: any;
};


export enum AgentTools  {
  RSSTOOL = "rss-feed-agent-tool",
  WEB3TOOL = "web3-agent-tool",
  WOOTOOL = "woo-agent-tool",
  REPLICATETOOL = "replicate-agent-tool",
}

export type TToolDataType = {[ i in AgentTools] : TToolData[] } 


export type TMessageSelectedLLM = {name: string; provider: string} 


export type TReplicateModel = {
  name: string | undefined;
  run_count: number | undefined;
  url: string | undefined;
  description: string | undefined;
  cover_image_url: string | undefined;
  latest_version: string | undefined;
  example_input: any; // Use a more specific type if known
  model: any;         // Use a more specific type if known
};
