import { useRef, useState } from "react";
import useAutosizeTextArea from "./useAutosizeTextarea";


const PostEditor = ({onChange, data, value, isExpanded}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, value);

  
  return (
    <textarea
      ref={textAreaRef}
      rows={1}
      className={` form-control mention-textarea ${isExpanded ? 'mention-expanded' : '' }`}
      value={value || ""}
      onChange={e => ( onChange(e) )}
      placeholder={"Start a new task."}
    >
    </textarea>
  );
};

export default PostEditor;


